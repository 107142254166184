import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { db } from './Firebase'; // Assuming Firebase is configured and imported correctly
import { collection, getDocs } from 'firebase/firestore';

const SliderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: theme.spacing(4),
}));

const SliderWrapper = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '&:hover .MuiIconButton-root': {
    opacity: 1,
  },
}));

const SlideTrack = styled(Box)(({ theme }) => ({
  display: 'flex',
  transition: 'transform 0.5s ease-in-out',
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  zIndex: 2,
}));


const VideoCard = styled(Card)(({ theme }) => ({
    width: '35vw', // viewport width의 28%로 설정
    maxWidth: '450px', // 너무 커지지 않도록 최대 너비 설정
    minWidth: '350px', // 너무 작아지지 않도록 최소 너비 설정
    margin: theme.spacing(1),
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: theme.shadows[8],
    },
    [theme.breakpoints.down('md')]: { // 태블릿 크기에서
      width: '45vw',
    },
    [theme.breakpoints.down('sm')]: { // 모바일 크기에서
      width: '85vw',
    },
  }));
const HighlightsSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [videos, setVideos] = useState([]); // State to hold video data

  useEffect(() => {
    const fetchVideos = async () => {
      const querySnapshot = await getDocs(collection(db, "research_highlight")); // Assuming "videos" is the collection name in Firestore
      const videosData = [];
      querySnapshot.forEach((doc) => {
        videosData.push(doc.data());
      });
      setVideos(videosData);
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    let interval;
    if (!isHovered) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => 
          prevIndex === videos.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000); // 3초마다 슬라이드 변경
    }
    return () => clearInterval(interval);
  }, [isHovered, videos.length]);

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <SliderContainer>
      {/* <Typography variant="h4" component="h2" gutterBottom>
        Research Highlights 🎥
      </Typography> */}
      
      <SliderWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <NavigationButton
          sx={{ left: 16 }}
          onClick={handlePrevSlide}
        >
          <ChevronLeft />
        </NavigationButton>

        <NavigationButton
          sx={{ right: 16 }}
          onClick={handleNextSlide}
        >
          <ChevronRight />
        </NavigationButton>

            <SlideTrack
              sx={{
                    transform: `translateX(-${currentIndex * (window.innerWidth * 0.28 + 16)}px)`, // 28vw + margin
              }}
            >
          {videos.map((video) => (
            <VideoCard key={video.id}>
                <CardMedia
                    component="iframe"
                    sx={{
                        border: 0,
                        aspectRatio: '16/9', // 16:9 비율 유지
                        height: 'auto', // 높이를 자동으로 조절
                        maxHeight: '350px' // 최대 높이 설정
                    }}
                    src={`https://www.youtube.com/embed/${video.link.includes('v=') ? video.link.split('v=')[1].split('&')[0] : video.link.split('.be/')[1].split('?')[0]}`}
                    title={video.name}
                    />
              <CardContent>
                <Typography variant="subtitle1" component="h3">
                  {video.name}
                </Typography>
              </CardContent>
            </VideoCard>
          ))}
        </SlideTrack>
      </SliderWrapper>
    </SliderContainer>
  );
};

export default HighlightsSlider;