
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// export const firebaseConfig = {
//   apiKey: "AIzaSyDRZsXOu6PIVdbAOXQ0a5ozMDubH0kxeVQ",
//   authDomain: "langlab-fc05d.firebaseapp.com",
//   projectId: "langlab-fc05d",
//   storageBucket: "langlab-fc05d.firebasestorage.app",
//   messagingSenderId: "566451813404",
//   appId: "1:566451813404:web:88c6d2e08dd9b8c9b573d9",
//   measurementId: "G-RZ32B4QTNF"
// };
export const firebaseConfig = {
    apiKey: "AIzaSyDktLBYapge1sdjfAQVENze9rpN1c3Fp_4",
    authDomain: "dli-lab.yonsei.ac.kr",
    projectId: "dli-lab",
    storageBucket: "dli-lab.appspot.com",
    messagingSenderId: "340642351903",
    appId: "1:340642351903:web:023be2ef5218a2572cfe2a",
    measurementId: "G-4E4MB9S44F"
  };

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
