import React from 'react';
import { Typography, Card, CardContent } from '@mui/material';
import { FaLightbulb, FaCommentDots, FaBrain, FaRobot } from 'react-icons/fa';
import Menubar from './Menubar';
import { AnimatedGradientBackground } from './Home';
import Footer from './Footer';

const ResearchPage = () => {
  const researchAreas = [
    { icon: <FaLightbulb size={32} />, title: 'Large Language Models (LLMs)' },
    { icon: <FaCommentDots size={32} />, title: 'Conversational AI' },
    { icon: <FaBrain size={32} />, title: 'Reasoner' },
    { icon: <FaRobot size={32} />, title: 'Agent' }
  ];

  const agiSteps = [
    { level: 1, title: 'Chatbots, AI with conversational language' },
    { level: 2, title: 'Reasoners, human-level problem solving' },
    { level: 3, title: 'Agents, systems that can take actions' },
    { level: 4, title: 'Innovators, AI that can aid in invention' },
    { level: 5, title: 'Organizations, AI that can do the work of an organization' }
  ];

  return (
    <div className="min-h-screen bg-[#F6F0E480]">
      <AnimatedGradientBackground>
      <Menubar />
      {/* Header Section */}
      <div className="container mx-auto px-4 py-16 max-w-6xl">
        <h1 className="text-4xl font-bold mb-8">Research</h1>
        
        {/* Overview Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Research Overview</h2>
          <p className="text-lg text-gray-700 mb-8">
            The Language & AGI Lab advances AI research by focusing on four key areas,
            addressing critical aspects of intelligent system development to enable seamless interaction, 
            robust reasoning, and autonomous decision-making.
          </p>

          {/* Research Areas Grid - Modified to 2x2 */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
            {researchAreas.map((area, index) => (
              <Card key={index} className="hover:shadow-lg transition-shadow">
                <CardContent className="flex flex-col items-center p-8 bg-[#430D79] text-white min-h-[200px] justify-center">
                  <div className="mb-4">
                    {area.icon}
                  </div>
                  <Typography variant="h6" className="text-center text-xl">
                    {area.title}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        {/* Rest of the code remains the same */}
        {/* AGI Steps Section */}
        <div className="mb-8 sm:mb-12 md:mb-16 bg-white rounded-lg p-4 sm:p-6 md:p-8 lg:p-10 shadow-md">
          <h2 className="text-3xl font-bold mb-8">5 Steps to AGI</h2>
          <div className="space-y-4">
            {agiSteps.map((step) => (
              <div key={step.level} className="flex items-center">
                <div className="w-16 shrink-0 font-semibold">Level {step.level}</div>
                <div 
                  className="rounded h-8 md:h-10 lg:h-12 flex items-center px-4 md:px-6 lg:px-8 text-white transition-all duration-300 hover:shadow-lg"
                  style={{
                    minHeight: "clamp(8px, 40vh, 100px)",
                    width: `${30 + (step.level * 5)}vw`, // Adjusted to use viewport width for versatility
                    backgroundColor: `rgba(67, 13, 121, ${0.6 + (step.level * 0.08)})`, // Using #430D79 with varying alpha based on step level
                  }}
                >
                  <span className="text-[clamp(0.1rem, 1vw, 2rem)] font-bold">
                  {step.title}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Research Areas Detail Sections */}
        <div className="space-y-16">
          <ResearchAreaDetail
            icon={<FaLightbulb />}
            title="Large Language Models (LLMs)"
            content="Our research on Large Language Models focuses on their potential as foundational tools for a wide range of AI applications. We aim to enhance their capabilities through methods like fine-tuning, prompt engineering, and multi-modal integration to handle diverse tasks such as language understanding, reasoning, and generative outputs. By addressing challenges like explainability, robustness, and ethical AI use, we strive to push the limits of what LLMs can achieve."
          />

          <ResearchAreaDetail
            icon={<FaCommentDots />}
            title="Conversational AI"
            content="At the Conversational AI level, our research centers on creating dialogue systems that are capable of sustaining meaningful, long-term conversations. This involves designing systems that adapt to user contexts, maintain memory of past interactions, and respond empathetically. By focusing on personalization and emotional intelligence, we aim to develop AI that feels natural, supportive, and relatable in a wide range of applications."
          />

          <ResearchAreaDetail
            icon={<FaBrain />}
            title="Reasoner"
            content="The Reasoner level emphasizes building AI systems that can think critically and reason effectively. This involves advancing models to handle complex problem-solving tasks, such as logical deduction, mathematical reasoning, and evidence-based decision-making. We aim to push the boundaries of AI reasoning by integrating structured thinking methods."
          />

          <ResearchAreaDetail
            icon={<FaRobot />}
            title="Agent"
            content="At the Agent level, our work focuses on developing autonomous systems that can perceive, learn, and act within dynamic environments. These agents are designed to understand their surroundings, adapt to new scenarios, and make informed decisions independently. The goal is to create AI that can operate reliably in real-world settings."
          />
        </div>
      </div>
      </AnimatedGradientBackground>
      <Footer />
    </div>
  );
};

const ResearchAreaDetail = ({ icon, title, content }) => (
  <Card className="w-full">
    <CardContent className="p-8">
      <div className="flex items-center mb-4">
        <Typography variant="h4" component="h3" className="font-bold flex items-center ml-0">
          {title}
          <span className="ml-4">{icon}</span>
        </Typography>
      </div>
      <Typography variant="body1" className="text-gray-700">
        {content}
      </Typography>
    </CardContent>
  </Card>
);

export default ResearchPage;