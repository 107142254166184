import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home';
import People from './People';
import Publications from './Publications';
import JoinPage from './Apply';
import News from './News';
import GalleryPage from './Gallery';
import Admin from './Admin';
import ResearchPage from './Research';


function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/people" element={<People />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/join" element={<JoinPage />} />
        <Route path="/news" element={<News />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/research" element={<ResearchPage />} />
      </Routes>
    </Router>
  );
}

export default App;
