import React, {useEffect, useState} from 'react';

// Firebase
import {db, storage} from './Firebase';
import {collection, addDoc, serverTimestamp, getDocs, deleteDoc, doc} from 'firebase/firestore';
import {ref, uploadBytes, getDownloadURL, deleteObject} from 'firebase/storage';
import {getAuth, onAuthStateChanged} from "firebase/auth";

// Layouts
import Menubar from "./Menubar";
import Footer from "./Footer";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';
import { AnimatedGradientBackground } from './Home';

const CloudUploadIcon = () => {
    return <svg height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 184.69 184.69"
                fill="#ffffff">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <g>
                <g>
                    <g>
                        <path style={{"fill": "#ffffff"}}
                              d="M149.968,50.186c-8.017-14.308-23.796-22.515-40.717-19.813 C102.609,16.43,88.713,7.576,73.087,7.576c-22.117,0-40.112,17.994-40.112,40.115c0,0.913,0.036,1.854,0.118,2.834 C14.004,54.875,0,72.11,0,91.959c0,23.456,19.082,42.535,42.538,42.535h33.623v-7.025H42.538 c-19.583,0-35.509-15.929-35.509-35.509c0-17.526,13.084-32.621,30.442-35.105c0.931-0.132,1.768-0.633,2.326-1.392 c0.555-0.755,0.795-1.704,0.644-2.63c-0.297-1.904-0.447-3.582-0.447-5.139c0-18.249,14.852-33.094,33.094-33.094 c13.703,0,25.789,8.26,30.803,21.04c0.63,1.621,2.351,2.534,4.058,2.14c15.425-3.568,29.919,3.883,36.604,17.168 c0.508,1.027,1.503,1.736,2.641,1.897c17.368,2.473,30.481,17.569,30.481,35.112c0,19.58-15.937,35.509-35.52,35.509H97.391 v7.025h44.761c23.459,0,42.538-19.079,42.538-42.535C184.69,71.545,169.884,53.901,149.968,50.186z"></path>
                    </g>
                    <g>
                        <path style={{"fill": "#ffffff"}}
                              d="M108.586,90.201c1.406-1.403,1.406-3.672,0-5.075L88.541,65.078 c-0.701-0.698-1.614-1.045-2.534-1.045l-0.064,0.011c-0.018,0-0.036-0.011-0.054-0.011c-0.931,0-1.85,0.361-2.534,1.045 L63.31,85.127c-1.403,1.403-1.403,3.672,0,5.075c1.403,1.406,3.672,1.406,5.075,0L82.296,76.29v97.227 c0,1.99,1.603,3.597,3.593,3.597c1.979,0,3.59-1.607,3.59-3.597V76.165l14.033,14.036 C104.91,91.608,107.183,91.608,108.586,90.201z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const renderSkeletons = () => {
    return Array.from({length: 8}).map((_, index) => (
        <div key={index} className="relative w-[360px] h-[300px] bg-gray-200 animate-pulse">
            <div className="flex items-center justify-center w-full h-full bg-gray-300 rounded dark:bg-gray-700">
                <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path
                        d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                </svg>
            </div>
        </div>
    ));
};

/**
 * Gallery
 *
 * @TODOS
 */
export default function GalleryPage() {
    const [title, setTitle] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pictures, setPictures] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!image) return alert("Please select an image!");

        try {
            setLoading(true);
            // Storage에 이미지 업로드
            const storageRef = ref(storage, `gallery/${title}-${new Date().getTime()}.png`);
            await uploadBytes(storageRef, image);
            const imageUrl = await getDownloadURL(storageRef);

            // Firestore에 데이터 저장
            await addDoc(collection(db, "gallery"), {
                date: serverTimestamp(),
                image: imageUrl,
                storagePath: storageRef.fullPath, // 이미지 경로를 Firestore에 저장
                name,
                title,
            });
        } catch (error) {
            console.error("Error uploading image or saving data:", error);
        }
        setImage(null)
        setAddModalOpen(false);
        setLoading(false);
    };

    const handleDelete = async (id, storagePath) => {
        try {
            // Firestore에서 문서 삭제
            await deleteDoc(doc(db, 'gallery', id));

            // Storage에서 이미지 삭제
            const storageRef = ref(storage, storagePath);
            await deleteObject(storageRef);

            setPictures(pictures.filter(picture => picture.id !== id));
        } catch (error) {
            console.error("Error deleting image or document:", error);
        }
    };


    useEffect(() => {
        const fetchPictures = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'gallery'));
                const galleryPictures = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
                galleryPictures.sort((a, b) => b.date.toDate() - a.date.toDate());
                setPictures(galleryPictures);
            } catch (error) {
                console.error("Error fetching pictures:", error);
            } finally {
                setIsFetching(false);
            }
        };
        if (!loading) {
            fetchPictures();
        }
    }, [loading]);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });
    }, []);

    return (
        <AnimatedGradientBackground>
        <div id="wrapper" className="flex flex-col">
            <Menubar/>
            <div className='flex flex-col justify-center bg-gray-100/80 mx-0 h-32'>
                    <h1 className='text-4xl font-bold md:ml-20 text-center md:text-left'>Gallery</h1>
                </div>
            <div className="container mx-auto px-4 mt-16 max-w-9xl mb-16">

                <section className="flex flex-col gap-6 items-center">


                    {isLoggedIn && <Button variant="contained"
                                           onClick={() => setAddModalOpen(prevState => !prevState)}>Add</Button>}

                    <div className="flex flex-wrap justify-center gap-4 w-4/5">
                        {isFetching ? renderSkeletons() : pictures?.sort((a, b) => a - b).map(({
                                                                                                   id,
                                                                                                   title,
                                                                                                   image,
                                                                                                   storagePath
                                                                                               }) => (
                            <div key={id} className="relative group w-[360px] h-[300px]">
                                <div
                                    className="absolute flex justify-center items-center rounded font-semibold text-2xl hover:opacity-100 opacity-0 inset-0 bg-white/0 group-hover:bg-white/30 backdrop-blur-sm transition duration-300">{title}
                                    {isLoggedIn && <Button color="error" onClick={() => handleDelete(id, storagePath)}>Delete</Button>}
                                </div>
                                <img src={image} alt={`${title}-${name}`}
                                     className="w-[360px] h-[300px] rounded object-fill"/>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
            <Footer/>

            <Dialog open={addModalOpen}>
                <DialogTitle>Picture Upload</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="title"
                        name="title"
                        label="Title"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={event => setTitle(event.currentTarget.value)}
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="author"
                        name="author"
                        label="Author"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={event => setName(event.currentTarget.value)}
                    />
                    <div className="flex justify-between items-center">
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon/>}
                        >
                            Upload image
                            <VisuallyHiddenInput
                                type="file"
                                onChange={(event) => setImage(event.currentTarget.files[0])}
                            />
                        </Button>

                        {image && <div className="text-sm font-semibold">{image.name}</div>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddModalOpen(prevState => !prevState)}>Cancel</Button>
                    <Button type="submit" onClick={handleSubmit}>Confirm</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={(theme) => ({color: '#fff', zIndex: 5000})}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
        </AnimatedGradientBackground>
    )
        ;
}
