import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import dliLogo from './images/dli_logo.svg';

export default function Menubar() {
  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'People', href: '/people' },
    { name: 'Publications', href: '/publications' },
    { name: 'Research', href: '/research' },
    { name: 'Gallery', href: '/gallery' },
    { name: 'Join Us', href: '/join' },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (

      <header className="absolute inset-x-0 top-0 z-50 sticky bg-[rgba(255,255,255,0.1)] shadow-nav backdrop-blur-sm w-screen px-4">
        <nav aria-label="Global" className="flex items-center justify-between w-full">
          <div className="flex lg:flex-1 min-h-12">
            <a href="/" className="-m-1.5 p-1.5 my-auto flex items-center">
              <span className="sr-only">Language & AGI Lab</span>
              <img src={dliLogo} alt="DLI Lab Logo" className="h-16 w-auto" />
              <h1 className="text-2xl font-bold text-[#444444] ml-2">Language & AGI Lab</h1>
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-6">
            {navigation.map((item) => (
              <div className="hover:bg-[#D9C5ED] rounded-lg px-3 py-2">
              <a key={item.name} href={item.href} className="text-lg font-semibold leading-6 text-[#8C1EA7] hover:text-[#8C1EA7]">
                {item.name}
              </a>
              </div>
            ))}
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              {/* <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">DLI Lab</span>
                <img src="images/dli_logo_dummy.webp" alt="DLI Lab Logo" className="h-8 w-auto" />
              </a> */}
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8C1EA7] hover:bg-[#D9C5ED] hover:text-[#8C1EA7]"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
  );
}