import Menubar from './Menubar';
import Footer from './Footer';
import { getAuth, onAuthStateChanged, signInWithRedirect, GoogleAuthProvider, signInWithEmailAndPassword,signOut } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth } from './Firebase';
import { Button, CircularProgress, Container, Typography, Box, TextField, Grid } from '@mui/material';

export default function Admin() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });
  }, []);

  const handleLogin = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);
  };
  const handleLoginWithEmail = async () => {
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password);
  };

  const handleLogout = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  return (
    <div>
      <Menubar />
      <Container className="min-h-screen">
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Box>
        ) : user ? (
          <Box textAlign="center" mt={5}>
            <Typography variant="h4" gutterBottom>
              Welcome, {user.email}!
            </Typography>
            <Button variant="contained" color="primary" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        ) : (
          <Box textAlign="center" mt={5}>
            <Typography variant="h4" gutterBottom>
              You are not logged in.
            </Typography>
            {/* <Button variant="contained" color="primary" onClick={handleLogin}>
              Login with Google
            </Button> */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
                <TextField label="Password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
                <Button variant="contained" color="primary" onClick={handleLoginWithEmail}>
                  Login with Email
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
      <Footer />
    </div>
  );
}