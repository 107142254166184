import { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, addDoc, updateDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from './Firebase';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Menubar from './Menubar';
import Footer from './Footer';
import yaml from 'js-yaml';
import { AnimatedGradientBackground } from './Home';

function Paper({ id, title, authors, venue, linksHTML, linkCode, linkProject, date, isLoggedIn, handleEdit, handleDelete }) {
  const underlineText = (text) => {
    return text.split('*').map((part, index) => 
      index % 2 === 1 ? <u key={index}>{part}</u> : part
    );
  };

  return (
    <div className="entry col-11 ml-12">
      <div className="grid-inner row g-0">
        <div className="col-md-12">
          <div className="entry-title title-sm">
            <b>{underlineText(title)}</b>
          </div>
          <div className="entry-content">
            <p>
              {underlineText(authors)}<br />
              <b>{underlineText(venue)}</b><br />
              <div className="flex space-x-4">
                {linksHTML && <a href={linksHTML} className='text-blue-700'>paper</a>}
                {linkCode && <a href={linkCode} className='text-blue-700'>code</a>}
                {linkProject && <a href={linkProject} className='text-blue-700'>project</a>}
              </div>
            </p>
            {isLoggedIn && (
              <div className='flex mt-2'>
                <Button variant="contained" color="primary" onClick={() => handleEdit(id)}>Edit</Button>
                <Button variant="contained" color="secondary" onClick={() => handleDelete(id)} className='ml-2'>Delete</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PaperForm({ open, handleClose, handleSubmit, paperToEdit, setPaperToEdit}) {
  const [title, setTitle] = useState('');
  const [authors, setAuthors] = useState('');
  const [venue, setVenue] = useState('');
  const [linksHTML, setLinksHTML] = useState('');
  const [linkCode, setLinkCode] = useState('');
  const [linkProject, setLinkProject] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    if (!open) {
      setTitle('');
      setAuthors('');
      setVenue('');
      setLinksHTML('');
      setLinkCode('');
      setLinkProject('');
      setDate('');
    }
  }, [open]);

  useEffect(() => {
    if (paperToEdit) {
      setTitle(paperToEdit.title);
      setAuthors(paperToEdit.authors);
      setVenue(paperToEdit.venue);
      setLinksHTML(paperToEdit.linksHTML);
      setLinkCode(paperToEdit.linkCode);
      setLinkProject(paperToEdit.linkProject);
      setDate(paperToEdit.date);
    }
  }, [paperToEdit]);

  const onSubmit = () => {
    if (paperToEdit) {
      handleSubmit({ id: paperToEdit.id , title, authors, venue, linksHTML, linkCode, linkProject, date });
    } else {
      handleSubmit({ title, authors, venue, linksHTML, linkCode, linkProject, date });
    }
    handleClose();
    setPaperToEdit(null);
  };
  const onCancel = () => {

    handleClose();
    setPaperToEdit(null);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{paperToEdit ? 'Edit Paper' : 'Add New Paper'}</DialogTitle>
      <DialogContent>
        <TextField autoFocus margin="dense" label="Title" fullWidth value={title} onChange={(e) => setTitle(e.target.value)} />
        <TextField margin="dense" label="Authors" fullWidth value={authors} onChange={(e) => setAuthors(e.target.value)} />
        <TextField margin="dense" label="Venue" fullWidth value={venue} onChange={(e) => setVenue(e.target.value)} />
        <TextField margin="dense" label="Links HTML" fullWidth value={linksHTML} onChange={(e) => setLinksHTML(e.target.value)} />
        <TextField margin="dense" label="Code Link" fullWidth value={linkCode} onChange={(e) => setLinkCode(e.target.value)} />
        <TextField margin="dense" label="Project Link" fullWidth value={linkProject} onChange={(e) => setLinkProject(e.target.value)} />
        <TextField margin="dense" label="Date (YYYY-MM-DD)" fullWidth value={date} onChange={(e) => setDate(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">Cancel</Button>
        <Button onClick={onSubmit} color="primary">{paperToEdit ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Publications() {
  const [publications, setPublications] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [paperToEdit, setPaperToEdit] = useState(null);

  useEffect(() => {
    const fetchPublications = async () => {
      const querySnapshot = await getDocs(collection(db, 'publications'));
      const publications = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPublications(publications);
    };
    fetchPublications();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  const handleDeletePaper = async (id) => {
    await deleteDoc(doc(db, 'publications', id));
    setPublications(publications.filter(paper => paper.id !== id));
  };

  const handleEditPaper = (id) => {
    const paper = publications.find(paper => paper.id === id);
    setPaperToEdit(paper);
    setOpenForm(true);
  };

  const handleAddPaper = async (newPaper) => {
    const docRef = await addDoc(collection(db, 'publications'), newPaper);
    setPublications([...publications, { id: docRef.id, ...newPaper }]);
  };  

  const handleUpdatePaper = async (updatedPaper) => {
    if (updatedPaper.id) {
      const docRef = doc(db, 'publications', updatedPaper.id);
      await updateDoc(docRef, updatedPaper);
      setPublications(publications.map(paper => paper.id === updatedPaper.id ? updatedPaper : paper));
    } else {
      console.error("Error: The paper to update does not have a valid ID.");
    }
  };

  const handleSubmitPaper = (paper) => {
    if (paper.id) {
      handleUpdatePaper(paper);
    } else {
      handleAddPaper(paper);
    }
    setPaperToEdit(null);
  };

  const handleUploadYaml = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const text = await file.text();
      const data = yaml.load(text);
      let currentIndex = 0; // Initialize an index counter
      for (const year in data) {
        for (const publication of data[year]) {
          await handleAddPaper({
            title: publication.title,
            authors: publication.authors,
            venue: publication.venue,
            linksHTML: publication.pdf_link,
            linkCode: '',
            linkProject: '',
            date: publication.publication_year === 'Preprint' ? 'Preprint' : `${publication.publication_year}-01-01`,
            index: currentIndex, // Assign the current index
          });
          currentIndex++; // Increment the index for each publication
        }
      }
    }
  };

  const groupedPublications = publications.reduce((acc, paper) => {
    const year = paper.date === 'Preprint' ? 'Preprint' : new Date(paper.date).getFullYear().toString();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(paper);
    return acc;
  }, {});

  return (
    <div className=''>
      <AnimatedGradientBackground>
      <Menubar />
      {/* <div>
        <pre>
          {JSON.stringify(publications, null, 2)}
        </pre>
        <div>
          {JSON.stringify(paperToEdit, null, 2)}
        </div>
      </div> */}
      <div className='flex flex-col justify-center bg-gray-100/80 mx-0 h-32'>
        <h1 className='text-4xl font-bold ml-20'>Publications</h1>
      </div>
      <div className='container mx-auto mb-20 min-h-screen'>
      {isLoggedIn && (
        <div>
          <Button variant="contained" color="primary" onClick={() => { setPaperToEdit(null); setOpenForm(true); }}>
            + Add
          </Button>
          <input
            accept=".yaml,.yml"
            style={{ display: 'none' }}
            id="upload-yaml-file"
            type="file"
            onChange={handleUploadYaml}
          />
          <label htmlFor="upload-yaml-file">
            <Button variant="contained" component="span" color="primary" className='ml-2'>
              Upload YAML
            </Button>
          </label>
        </div>
      )}
      {Object.keys(groupedPublications).sort((a, b) => {
    if (a === 'Preprint') return -1;
    if (b === 'Preprint') return 1;
    return b - a; // Sort years in descending order
  }).map(year => (
    <div key={year} className='mt-10'>
      <h2 className='text-2xl font-bold flex items-center pl-5 md:pl-0'>
        {year}
        <span className='flex-grow border-t border-gray-200 ml-4'></span>
      </h2>
      <div className="flex flex-col gap-4">
        {groupedPublications[year]
          .sort((a, b) => a.index - b.index) // Sort papers by their index
          .map(paper => (
            <Paper
              key={paper.id}
              {...paper}
              isLoggedIn={isLoggedIn}
              handleEdit={handleEditPaper}
              handleDelete={handleDeletePaper}
              setPaperToEdit={setPaperToEdit}
            />
          ))}
      </div>
    </div>
  ))}
      </div>
      </AnimatedGradientBackground>
      <Footer />
      <PaperForm
        open={openForm}
        handleClose={() =>  setOpenForm(false)}
        handleSubmit={handleSubmitPaper}
        paperToEdit={paperToEdit}
        setPaperToEdit={setPaperToEdit}
      />
    </div>
  );
}