import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { db, storage } from './Firebase';
import { doc, collection, getDocs, addDoc, updateDoc, deleteDoc, orderBy } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useEffect, useState } from 'react';
import Menubar from './Menubar';
import Footer from './Footer';
import yaml from 'js-yaml';
import { AnimatedGradientBackground } from './Home';

function Person({ id, name, status, email, photo, personal_website, interests, isLoggedIn, handleEdit, handleDelete, avatarSize, index, handleIndexChange }) {
    const photoUrl = photo && (photo.startsWith('http') ? photo : photo.startsWith('../') ? `${process.env.PUBLIC_URL}/${photo.slice(3)}` : `${process.env.PUBLIC_URL}/${photo}`);
    return (
        <div className='inline-flex flex-col items-center md:flex-row p-4 w-96'>
            {isLoggedIn && <IconButton onClick={() => handleIndexChange(id, 'decrease')}><ArrowLeftIcon /></IconButton>}
            {avatarSize === "lg" && <Avatar src={photoUrl} className={"w-44 h-44 mr-4"}/>}
            {avatarSize === "md" && <Avatar src={photoUrl} className={"w-40 h-40 mr-4"}/>}
            {avatarSize === "sm" && <Avatar src={photoUrl} className={"w-32 h-32 mr-4"}/>}
            <div className='flex flex-col w-40'>
                <div className='text-xl font-bold'>{name}</div>
                <div className='text-lg text-gray-500'>{status}</div>
                <div className='text-lg font-light text-gray-500 pt-5'>{email}</div>
                {interests && <div className='text-sm text-gray-400 pt-2'>{interests.join(', ')}</div>}
                <a href={personal_website} className='text-lg text-blue-700'>website</a>
                {isLoggedIn && (
                    <div className='flex mt-2'>
                        <Button variant="contained" color="primary" onClick={() => handleEdit(id)}>Edit</Button>
                        <Button variant="contained" color="secondary" onClick={() => handleDelete(id)}
                                className='ml-2'>Delete</Button>
                    </div>
                )}
            </div>
            {isLoggedIn && <IconButton onClick={() => handleIndexChange(id, 'increase')}><ArrowRightIcon/></IconButton>}
        </div>
    );
}

function AddPersonForm({open, handleClose, handleAdd, personToEdit, handleUpdate}) {
    const [name, setName] = useState(personToEdit ? personToEdit.name : '');
    const [status, setStatus] = useState(personToEdit ? personToEdit.status : '');
    const [email, setEmail] = useState(personToEdit ? personToEdit.email : '');
    const [photo, setPhoto] = useState(null);
    const [personal_website, setPersonalWebsite] = useState(personToEdit ? personToEdit.personal_website : '');

    useEffect(() => {
        if (personToEdit) {
            setName(personToEdit.name);
            setStatus(personToEdit.status);
            setEmail(personToEdit.email);
            setPersonalWebsite(personToEdit.personal_website);
        }
    }, [personToEdit]);

    const handleSubmit = async () => {
        let photoURL = personToEdit ? personToEdit.photo : '';
        if (photo && !photo.startsWith('http')) {
            const storageRef = ref(storage, `photos/${photo.name}`);
            await uploadBytes(storageRef, photo);
            photoURL = await getDownloadURL(storageRef);
        } else if (photo && photo.startsWith('http')) {
            photoURL = photo;
        }
        if (personToEdit) {
            handleUpdate({id: personToEdit.id, name, status, email, photo: photoURL, personal_website});
        } else {
            handleAdd({name, status, email, photo: photoURL, personal_website});
        }
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{personToEdit ? 'Edit Person' : 'Add New Person'}</DialogTitle>
            <DialogContent>
                <TextField autoFocus margin="dense" label="Name" fullWidth value={name}
                           onChange={(e) => setName(e.target.value)}/>
                <TextField margin="dense" label="Status" fullWidth value={status}
                           onChange={(e) => setStatus(e.target.value)}/>
                <TextField margin="dense" label="Email" fullWidth value={email}
                           onChange={(e) => setEmail(e.target.value)}/>
                <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="raised-button-file"
                    type="file"
                    onChange={(e) => setPhoto(e.target.files[0])}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span">
                        Upload Photo
                    </Button>
                </label>
                {photo && <span className="ml-2">{photo.name}</span>}
                <TextField margin="dense" label="Personal Website" fullWidth value={personal_website}
                           onChange={(e) => setPersonalWebsite(e.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>{personToEdit ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );
}

function ConfirmDeleteDialog({open, handleClose, handleConfirm}) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <div>Are you sure you want to delete this person?</div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm} color="secondary">Delete</Button>
            </DialogActions>
        </Dialog>
    );
}

export default function People() {
    const [labMembers, setLabMembers] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [openAddForm, setOpenAddForm] = useState(false);
    const [personToEdit, setPersonToEdit] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [personToDelete, setPersonToDelete] = useState(null);
    const [avatarSize, setAvatarSize] = useState("md");
    const [columns, setColumns] = useState(3);

    useEffect(() => {
        const fetchLabMembers = async () => {
            const querySnapshot = await getDocs(collection(db, 'lab_members'), orderBy('index', 'asc'));
            const labMembers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            labMembers.sort((a, b) => a.index - b.index);
            console.log("Fetched Lab Members:", labMembers);
            setLabMembers(labMembers);
        };
        fetchLabMembers();
    }, []);
    const handleAddPerson = async (newPerson) => {
        const querySnapshot = await getDocs(collection(db, 'lab_members'));
        const docRef = await addDoc(collection(db, 'lab_members'), { ...newPerson, index: querySnapshot.size });
        setLabMembers([...labMembers, { id: docRef.id, ...newPerson, index: querySnapshot.size }]);
    };

    const handleUpdatePerson = async (updatedPerson) => {
        const docRef = doc(db, 'lab_members', updatedPerson.id);
        await updateDoc(docRef, updatedPerson);
        setLabMembers(labMembers.map(member => member.id === updatedPerson.id ? updatedPerson : member));
    };

    const handleDeletePerson = async (id) => {
        await deleteDoc(doc(db, 'lab_members', id));
        setLabMembers(labMembers.filter(member => member.id !== id));
    };

    const handleConfirmDelete = () => {
        handleDeletePerson(personToDelete);
        setOpenConfirmDelete(false);
        setPersonToDelete(null);
    };



    const handleUploadYaml = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const text = await file.text();
            const data = yaml.load(text);
            for (const category in data) {
                for (const person of data[category]) {
                    await handleAddPerson({
                        name: person.name,
                        status: person.status ? person.status : null,
                        email: person.email,
                        photo: person.photo,
                        personal_website: person.personal_website ? person.personal_website : null,
                        interests: person.interests ? person.interests.split(', ') : []
                    });
                }
            }
        }
    };

    const handleIndexChange = async (id, direction) => {
        const person = labMembers.find(member => member.id === id);
        const currentIndex = person.index;
        const newIndex = direction === 'increase' ? currentIndex + 1 : currentIndex - 1;
        const otherPerson = labMembers.find(member => member.index === newIndex && member.id !== id);
        if (otherPerson) {
            await updateDoc(doc(db, 'lab_members', otherPerson.id), { index: currentIndex });
        }
        await updateDoc(doc(db, 'lab_members', id), { index: newIndex });
        setLabMembers(labMembers.map(member => member.id === id ? { ...member, index: newIndex } : member.id === otherPerson?.id ? { ...otherPerson, index: currentIndex } : member).sort((a, b) => a.index - b.index));
    };

    

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });
    }, []);

    

    return (
            <div className=''>
                <AnimatedGradientBackground>
                <Menubar/>
                {/* <div>
                    <pre>
                        {JSON.stringify(labMembers, null, 2)}
                    </pre>
                </div> */}
                <div className='flex flex-col justify-center bg-gray-100/80 mx-0 h-32'>
                    <h1 className='text-4xl font-bold md:ml-20 text-center md:text-left'>People</h1>
                </div>
                <div className='container mx-auto'>
                
                    <div className='mt-10'>
                        <div className='flex justify-between items-center mb-4'>
                            <h1 className='text-2xl font-bold flex items-center pl-5 md:pl-0'>Principal Investigator</h1>
                            <span className='flex-grow border-t border-gray-200 ml-4'></span>
                            {isLoggedIn && (
                                <div>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        setPersonToEdit(null);
                                        setOpenAddForm(true);
                                    }}>
                                        + Add
                                    </Button>
                                    <input
                                        accept=".yaml,.yml"
                                        style={{display: 'none'}}
                                        id="upload-yaml-file"
                                        type="file"
                                        onChange={handleUploadYaml}
                                    />
                                    <label htmlFor="upload-yaml-file">
                                        <Button variant="contained" component="span" color="primary" className='ml-2'>
                                            Upload YAML
                                        </Button>
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className='flex justify-between items-center mb-4'>
                            {isLoggedIn && (
                                <div>
                                    <Button variant="contained" onClick={() => setAvatarSize("sm")}>Small Avatar</Button>
                                    <Button variant="contained" onClick={() => setAvatarSize("md")} className='ml-2'>Medium
                                        Avatar</Button>
                                    <Button variant="contained" onClick={() => setAvatarSize("lg")} className='ml-2'>Large
                                        Avatar</Button>
                                </div>
                            )}
                            {isLoggedIn && (
                                <div>
                                    <Button variant="contained" onClick={() => setColumns(2)}>2 Columns</Button>
                                    <Button variant="contained" onClick={() => setColumns(3)} className='ml-2'>3
                                        Columns</Button>
                                    <Button variant="contained" onClick={() => setColumns(4)} className='ml-2'>4
                                        Columns</Button>
                                </div>
                            )}
                        </div>
                        <div
                            className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${columns} lg:grid-cols-${columns} gap-4`}>
                            {labMembers.filter(member => member.name && member.name.includes('Jinyoung Yeo')).map((member) => (
                                <Person
                                    key={member.id}
                                    {...member}
                                    isLoggedIn={isLoggedIn}
                                    handleEdit={(id) => {
                                        setPersonToEdit(labMembers.find(member => member.id === id));
                                        setOpenAddForm(true);
                                    }}
                                    handleDelete={(id) => {
                                        setPersonToDelete(id);
                                        setOpenConfirmDelete(true);
                                    }}
                                    avatarSize={avatarSize}
                                    index={member.index}
                                    handleIndexChange={handleIndexChange}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='mt-10'>
                        <div className='flex justify-between items-center mb-4'>
                            <h1 className='text-2xl font-bold flex items-center pl-5 md:pl-0'>Research Assistant</h1>
                            <span className='flex-grow border-t border-gray-200 ml-4'></span>
                        </div>
                        <div
                            className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${columns} lg:grid-cols-${columns} gap-4`}>
                            {labMembers.filter(member => member.status && (member.status.includes('PhD') || member.status.includes('Master'))).map((member) => (
                                <Person
                                    key={member.id}
                                    {...member}
                                    isLoggedIn={isLoggedIn}
                                    handleEdit={(id) => {
                                        setPersonToEdit(labMembers.find(member => member.id === id));
                                        setOpenAddForm(true);
                                    }}
                                    handleDelete={(id) => {
                                        setPersonToDelete(id);
                                        setOpenConfirmDelete(true);
                                    }}
                                    avatarSize={avatarSize}
                                    index={member.index}
                                    handleIndexChange={handleIndexChange}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='mt-10'>
                        <div className='flex justify-between items-center mb-4'>
                            <h1 className='text-2xl font-bold flex items-center pl-5 md:pl-0'>Intern</h1>
                            <span className='flex-grow border-t border-gray-200 ml-4'></span>
                        </div>
                        <div
                            className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${columns} lg:grid-cols-${columns} gap-4`}>
                            {labMembers.filter(member => member.status && (member.status.includes('Intern'))).map((member) => (
                                <Person
                                    key={member.id}
                                    {...member}
                                    isLoggedIn={isLoggedIn}
                                    handleEdit={(id) => {
                                        setPersonToEdit(labMembers.find(member => member.id === id));
                                        setOpenAddForm(true);
                                    }}
                                    handleDelete={(id) => {
                                        setPersonToDelete(id);
                                        setOpenConfirmDelete(true);
                                    }}
                                    avatarSize={avatarSize}
                                    index={member.index}
                                    handleIndexChange={handleIndexChange}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='mt-10'>
                        <div className='flex justify-between items-center mb-4'>
                            <h1 className='text-2xl font-bold flex items-center pl-5 md:pl-0'>Collaborator</h1>
                            <span className='flex-grow border-t border-gray-200 ml-4'></span>
                        </div>
                        <div
                            className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${columns} lg:grid-cols-${columns} gap-4`}>
                            {labMembers.filter(member => member.status && (member.status.includes('Collaborator'))).map((member) => (
                                <Person
                                    key={member.id}
                                    {...member}
                                    isLoggedIn={isLoggedIn}
                                    handleEdit={(id) => {
                                        setPersonToEdit(labMembers.find(member => member.id === id));
                                        setOpenAddForm(true);
                                    }}
                                    handleDelete={(id) => {
                                        setPersonToDelete(id);
                                        setOpenConfirmDelete(true);
                                    }}
                                    avatarSize={avatarSize}
                                    index={member.index}
                                    handleIndexChange={handleIndexChange}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='mt-10'>
                        <div className='flex justify-between items-center mb-4'>
                            <h1 className='text-2xl font-bold flex items-center pl-5 md:pl-0'>Administrative Staff</h1>
                            <span className='flex-grow border-t border-gray-200 ml-4'></span>
                        </div>
                        <div
                            className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${columns} lg:grid-cols-${columns} gap-4`}>
                            {labMembers.filter(member => member.status && (member.status.includes('Staff'))).map((member) => (
                                <Person
                                    key={member.id}
                                    {...member}
                                    isLoggedIn={isLoggedIn}
                                    handleEdit={(id) => {
                                        setPersonToEdit(labMembers.find(member => member.id === id));
                                        setOpenAddForm(true);
                                    }}
                                    handleDelete={(id) => {
                                        setPersonToDelete(id);
                                        setOpenConfirmDelete(true);
                                    }}
                                    avatarSize={avatarSize}
                                    index={member.index}
                                    handleIndexChange={handleIndexChange}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <AddPersonForm
                    open={openAddForm}
                    handleClose={() => setOpenAddForm(false)}
                    handleAdd={handleAddPerson}
                    personToEdit={personToEdit}
                    handleUpdate={handleUpdatePerson}
                />
                <ConfirmDeleteDialog
                    open={openConfirmDelete}
                    handleClose={() => setOpenConfirmDelete(false)}
                    handleConfirm={handleConfirmDelete}
                />
                </AnimatedGradientBackground>
                <Footer/>
            </div>
    );
}