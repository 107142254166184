import React, { useState, useEffect } from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from './Firebase';
import LinkIcon from '@mui/icons-material/Link';


const NewsContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
  padding: theme.spacing(4),
}));

const NewsItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  borderBottom: '1px solid #e0e0e0',
  '&:last-child': {
    borderBottom: 'none'
  }
}));

const CategoryChip = styled(Chip)(({ category }) => {
  const getColor = () => {
    switch (category) {
      case 'Academic':
        return { bg: '#e3f2fd', color: '#1976d2' };
      case 'News':
        return { bg: '#e8f5e9', color: '#2e7d32' };
      case 'Awards':
        return { bg: '#fff3e0', color: '#ed6c02' };
      default:
        return { bg: '#f5f5f5', color: '#616161' };
    }
  };
  const colors = getColor();
  return {
    backgroundColor: colors.bg,
    color: colors.color,
    fontWeight: 500,
    marginRight: '12px'
  };
});

const NewsTimeline = () => {
  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      const q = query(collection(db, "news_content"), orderBy("date", "desc"));
      const querySnapshot = await getDocs(q);
      const news = [];
      querySnapshot.forEach((doc) => {
        news.push({ id: doc.id, ...doc.data() });
      });
      setNewsItems(news);
    };

    fetchNews();
  }, []);

  return (
    <Box sx={{ bgcolor: '#F6F0E480', py: 8, width: '100vw' }}>
  <NewsContainer>
    <Typography
      variant="h4"
      component="h3"
      sx={{
        fontWeight: 'bold',
        mb: 4,
        fontSize: '1.5rem',
      }}
    >
      News & Updates 📰
    </Typography>

    {newsItems.map((item) => (
      <NewsItem key={item.id}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <CategoryChip
            label={item.category}
            category={item.category}
            size="small"
          />
          <Typography variant="subtitle2" color="text.secondary">
            {new Date(item.date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Typography>
        </Box>
        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
        <Typography variant="body1">{item.description} &nbsp;
          {item.link1 && (
            <a href={item.link1} target="_blank" rel="noopener noreferrer">
              <LinkIcon sx={{ color: '#BEBEBE', fontSize: '32px'}}>🔗</LinkIcon>
            </a>
          )}
          {item.link2 && (
            <a href={item.link2} target="_blank" rel="noopener noreferrer">
              <LinkIcon sx={{ color: '#BEBEBE', fontSize: '32px' }}>🔗</LinkIcon>
            </a>
          )}
          {item.link3 && (
            <a href={item.link3} target="_blank" rel="noopener noreferrer">
              <LinkIcon sx={{ color: '#BEBEBE', fontSize: '32px' }}>🔗</LinkIcon>
            </a>
          )}
          </Typography>
        </Box>
      </NewsItem>
    ))}
  </NewsContainer>
</Box>
  );
};

export default NewsTimeline;