import { useState, useEffect } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Menubar from './Menubar';
import Footer from './Footer';
import { db } from './Firebase';
import { collection, getDocs } from 'firebase/firestore';
import HighlightsSlider from './VideoSlider';
import NewsTimeline from './NewsTimeLine';
const faqList = [
  {
    question: "What is your research group focused on?",
    answer: "Our research group focuses on a wide range of topics related to natural language processing (NLP) and data mining (DM). Recently, we aim to utilize large language models (LLMs) and enhance their reasoning capability for real-world applications, including task-oriented conversational agents and recommendation."
  },
  {
    question: "Are you currently accepting new members?",
    answer: "Yes, we are currently accepting new members. We welcome passionate students (MS/PhD) and undergraduate research interns to join our team."
  },
  {
    question: "Do I need to have any qualifications to join your research group?",
    answer: "We strongly recommend individuals who are interested in joining our research group to complete a research internship with us before applying for a graduate research position. This will give you the opportunity to learn more about our research and determine if it is the right fit for you. Additionally, having relevant research experience and a passion for our research area will be helpful in making a strong application."
  },
  {
    question: "How do I apply to join your research group?",
    answer: "To apply to join our research group, please email your resume/CV and a brief statement of interest to <a href='mailto:jinyeo@yonsei.ac.kr'><strong>jinyeo@yonsei.ac.kr</strong></a> or <a href='mailto:donalee@yonsei.ac.kr'><strong>donalee@yonsei.ac.kr</strong></a>."
  },
  {
    question: "Can undergraduate students apply to join your research group?",
    answer: "Yes, undergraduate students are welcome to apply to join our research group as research interns. We offer opportunities for undergraduate students to gain research experience and contribute to ongoing research projects."
  },
  {
    question: "What kind of mentorship and guidance can I expect as a research group member?",
    answer: "As a research group member, you can expect mentorship and guidance from the research group leader and senior members of the group. We offer regular group meetings, one-on-one meetings, and training opportunities to support the development of our members."
  }
];

function FAQ() {
  return (
    <div className="mx-auto">
      <div className="mx-auto w-full max-w-4xl divide-y divide-gray-200 rounded-xl bg-white shadow-lg">
        {faqList.map((faq, index) => (
          <Disclosure as="div" className="p-6" key={index}>
            <DisclosureButton className="group flex items-center justify-between w-full">
              <span className="text-base font-medium text-gray-900 group-hover:text-gray-700">
                {faq.question}
              </span>
              <ChevronDownIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-400 group-open:rotate-180 transform transition-transform duration-200" />
            </DisclosureButton>
            <DisclosurePanel className="mt-2 text-gray-600">
              <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </DisclosurePanel>
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

export const AnimatedGradientBackground = ({ children }) => {
  return (
    <div className="relative w-screen overflow-hidden">
      {/* Base animated layer */}
      <div 
        className="absolute inset-0 animate-gradient-base"
        style={{
          background: `
            radial-gradient(circle at 0% 0%, rgba(255, 220, 255, 0.95) 0%, transparent 25%),
            radial-gradient(circle at 100% 0%, rgba(220, 235, 255, 0.95) 0%, transparent 25%),
            radial-gradient(circle at 100% 100%, rgba(255, 210, 255, 0.95) 0%, transparent 25%),
            radial-gradient(circle at 0% 100%, rgba(210, 220, 255, 0.95) 0%, transparent 25%)
          `,
          backgroundColor: '#FFFEF9',
          backgroundSize: '200% 200%'
        }}
      />
      
      {/* Secondary animated layer with more distinct colors */}
      <div 
        className="absolute inset-0 animate-gradient-secondary opacity-85"
        style={{
          background: `
            radial-gradient(circle at 50% 50%, rgba(255, 225, 255, 0.9) 0%, transparent 30%),
            radial-gradient(circle at 100% 50%, rgba(225, 235, 255, 0.9) 0%, transparent 30%)
          `,
          backgroundSize: '200% 200%'
        }}
      />

      {/* Third layer for color variation */}
      <div 
        className="absolute inset-0 animate-gradient-tertiary opacity-70"
        style={{
          background: `
            radial-gradient(circle at 30% 70%, rgba(255, 215, 255, 0.8) 0%, transparent 20%),
            radial-gradient(circle at 70% 30%, rgba(215, 225, 255, 0.8) 0%, transparent 20%)
          `,
          backgroundSize: '200% 200%'
        }}
      />

      {/* Content layer */}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default function Home() {
  const [highlightsContents, setHighlightsContents] = useState([]);

  useEffect(() => {
    const fetchHighlights = async () => {
      const querySnapshot = await getDocs(collection(db, "research_highlight"));
      const highlights = [];
      querySnapshot.forEach((doc) => {
        highlights.push(doc.data());
      });
      setHighlightsContents(highlights);
    };

    fetchHighlights();
  }, []);

  return (
    <div id="wrapper" className="flex flex-col p-0">
      <AnimatedGradientBackground className="container m-0 p-0">
      <Menubar />
        <div className="content-wrap">
          <div className="flex-col w-screen">
            <div style={{ width: '100vw' }}>
              <div className="heading-block text-center flex-col gap-y-3 rounded-lg p-4 max-w-6xl mx-auto my-12">
                <h1 className="text-balance text-5xl font-bold tracking-tight text-[#444444] sm:text-6xl">Yonsei Language & AGI Lab</h1>
                <div className="text-2xl font-light pt-5 text-[#666666]">We develop LLM-Driven Synergistic AI Systems that integrate the natural communication abilities of LLMs with structured reasoning and dependable execution. By bridging LLMs with multimodal data, knowledge systems, and autonomous capabilities, we craft AI that is more reliable, adaptable, and impactful.</div>
              </div>
            </div>
            <div className="flex-col w-screen bg-[#F6F0E480]">
              <div className="fancy-title title-border mx-auto my-8 max-w-6xl py-8">
                <h3 className="text-2xl font-bold flex items-center pl-5 md:pl-0">Research Highlights 🎥</h3>
                <div className="youtube-highlights mx-auto max-w-6xl">
                  <HighlightsSlider />
                </div>
              </div>
            </div>
            <NewsTimeline />
            {/* <NewsUpload /> */}
          </div>
        </div>
      </AnimatedGradientBackground>
      <Footer />
    </div>
  );

}