
export default function Footer() {
  return (
    <footer id="footer" className="dark bg-gray-800 text-white py-8">
      <div className="container mx-auto">
        <div className="footer-widgets-wrap ml-20">
            <div className="row mb-12">
              <div className="col-lg-10 mx-auto">
                <div className="row mb-12">
                  <div className="col-md-9">
                    <div className="widget text-gray-300">
                      <p><strong>Visit Us</strong></p>
                      Yonsei Engineering Research Park (YERP) 425C <br />
                      50 Yonsei-ro, Seodaemun-gu, Seoul, Republic of Korea (03722)
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="copyrights" className="bg-gray-900 py-4">
          <div className="container mx-auto">
            <div className="row">
              <div className="col-md-12 text-center">
                &copy; 2024. Yonsei University Language & AGI Lab. <br /> All rights reserved.<br />
              </div>
            </div>
          </div>
        </div>
    </footer>
  );
}