import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Button, Chip } from '@mui/material';
import { collection, getDocs, addDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './Firebase';
import yaml from 'js-yaml';
import Menubar from './Menubar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AnimatedGradientBackground } from './Home';
import Footer from './Footer';

// Function to fetch user role
const fetchUserRole = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, "users", uid)); // Assuming you have a 'users' collection
    if (userDoc.exists()) {
      console.log('User Document Data:', userDoc.data()); // Debugging line
      return userDoc.data().role; // Adjust based on your data structure
    } else {
      console.log('No such user document!'); // Debugging line
      return null;
    }
  } catch (error) {
    console.error('Error fetching user role:', error); // Error handling
    return null;
  }
};

const ProjectCard = ({ project }) => {
  const isRecruiting = project.status === 'recruiting';
  
  return (
    <Card className="w-full hover:shadow-lg transition-all duration-300" style={{ minHeight: '500px' }}>
      <CardContent className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div className="flex-grow">
            <Typography variant="h5" component="h3" className="font-bold mb-2">
              {project.title}
            </Typography>
            <Typography variant="subtitle1" className="text-gray-600 mb-1">
              Led by {project.lead}
            </Typography>
            <div className="mb-4">
              <Chip 
                label={isRecruiting ? 'Currently Recruiting' : 'Application Closed'} 
                color={isRecruiting ? 'default' : 'default'}
                style={{ backgroundColor: isRecruiting ? '#CF9FFF' : '#D3D3D3', color: 'white' }}
                className="mr-2"
              />
              {project.positions !== 0 && (
                <Chip 
                  label={`${project.positions} position${project.positions > 1 ? 's' : ''}`}
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </div>

        <Typography variant="body1" className="text-gray-700 mb-4" style={{ minHeight: '60%', whiteSpace: 'normal' }}>
          {project.description}
        </Typography>

        {project.requirements !== '-' && (
          <Box className="mb-4">
            <Typography variant="subtitle2" className="font-bold mb-1">
              Requirements:
            </Typography>
            <Typography variant="body2" className="text-gray-600">
              {project.requirements}
            </Typography>
          </Box>
        )}

        {project.references && project.references.length > 0 && (
          <Box className="mb-4">
            <Typography variant="subtitle2" className="font-bold mb-1">
              References:
            </Typography>
            <ul className="list-disc pl-4">
              {project.references.map((ref, index) => (
                <li key={index} className="text-gray-600 text-sm">
                  {ref.link ? (
                    <a href={ref.link} target="_blank" rel="noopener noreferrer" className="text-purple-600 hover:text-purple-800">
                      {ref.title}
                    </a>
                  ) : (
                    ref.title
                  )}
                </li>
              ))}
            </ul>
          </Box>
        )}

        {isRecruiting && (
          <Button 
            variant="contained"
            className="bg-purple-600 hover:bg-purple-700"
            href={`mailto:${project.contact}`}
          >
            Apply Now
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

const JoinPage = () => {
  const [projects, setProjects] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      const querySnapshot = await getDocs(collection(db, "projects"));
      const projectsData = [];
      querySnapshot.forEach((doc) => {
        projectsData.push({ id: doc.id, ...doc.data() });
      });
      setProjects(projectsData);
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            setIsLoggedIn(true);
            const userRole = await fetchUserRole(user.uid);
            console.log('User Role:', userRole);
            setIsAdmin(userRole === 'admin');
            console.log('Is Admin:', userRole === 'admin');
        } else {
            setIsLoggedIn(false);
            setIsAdmin(false);
        }
    });
  }, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const yamlContent = e.target.result;
        const parsedData = yaml.load(yamlContent);
        
        // Upload to Firestore
        const projectsCollection = collection(db, 'projects');
        for (const project of parsedData.projects) {
          await addDoc(projectsCollection, {
            ...project,
            createdAt: new Date().toISOString()
          });
        }
        alert('Projects uploaded successfully!');
      } catch (error) {
        console.error('Error uploading projects:', error);
        alert('Error uploading projects');
      }
    };
    reader.readAsText(file);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <AnimatedGradientBackground>
        <Menubar />
        <div className="container mx-auto px-4 py-16 max-w-6xl">
          <h1 className="text-4xl font-bold mb-12">Join Us</h1>
  
          {isAdmin && (
            <Box className="mb-8">
              <Button
                variant="contained"
                component="label"
                className="bg-purple-600 hover:bg-purple-700"
              >
                Upload Projects YAML
                <input
                  type="file"
                  accept=".yaml,.yml"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
            </Box>
          )}
  
          {/* Join Us Description */}
          <Card className="mb-12">
            <CardContent className="p-8">
              <Typography variant="body1" className="text-lg text-gray-700">
                LangAGI Lab is seeking talented and motivated students (graduate students and
                undergraduate interns) to join our team. If you are passionate about advancing AI
                research, please feel free to contact{' '}
                <a href="mailto:jinyeo@yonsei.ac.kr" className="text-purple-600 hover:text-purple-800">
                  jinyeo@yonsei.ac.kr
                </a>{' '}
                for inquiries.
              </Typography>
            </CardContent>
          </Card>
  
          {/* Research Projects */}
          <div className="space-y-6">
            <Typography variant="h4" component="h2" className="font-bold mb-8">
              Research Projects
            </Typography>
  
            {/* Active Projects */}
            <div className="mb-12">
              <div className="space-y-6">
                {projects
                  .filter(project => project.status === 'recruiting')
                  .map((project, index) => (
                    <ProjectCard key={index} project={project} />
                  ))}
              </div>
            </div>
  
            {/* Past Projects */}
            <div>
              <div className="space-y-6">
                {projects
                  .filter(project => project.status === 'not recruiting')
                  .map((project, index) => (
                    <ProjectCard key={index} project={project} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </AnimatedGradientBackground>
      <Footer />
    </div>
  );
}

export default JoinPage;